import React, { useState } from "react";
import { Card, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEnvelope,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import dropdown from "../../assets/dropdown.svg";
import Toggle from "react-toggle";
import disabledframe from "../../assets/disabled.svg";
import moment from "moment";
import placeholder from "../../assets/schoolimage.png";
import { Link } from "react-router-dom";
import SendSchoolMail from "components/modals/SendSchoolMail";
import DeleteSchoolModal from "components/modals/DeleteSchoolModal";

const SchoolCards = ({
  id,
  Name,
  ExpiryDate,
  image,
  isActive = true,
  handleToggleChange,
  handleDelete,
  number,
  email,
  designation,
  showToggle = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const formattedExpiryDate = moment(ExpiryDate).format("MMM D, YYYY");

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleConfirmDelete = () => {
    handleDelete();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Card
        className="text-center my-3 py-2 border-0 school-cards text-decoration-none"
        style={{
          borderRadius: "20px",
          overflow: "hidden",
          backgroundColor: !isActive ? "#fbe5e5" : "#ffffff",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="text-end me-2">
          <Dropdown>
            <Dropdown.Toggle
              className="more bg-transparent text-black border-0 e-caret-hide rounded-0"
              id="dropdown-basic"
            >
              <Card.Img
                style={{ width: "24px", height: "24px" }}
                variant="top"
                src={dropdown}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="border-0"
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <Dropdown.Item as={Link} to={`edit-school/${id}`}>
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleShowDeleteModal}
                style={{ color: "red" }}
              >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            margin: "auto",
            borderRadius: "60px",
            overflow: "hidden",
            border: isActive ? "5px solid #ea4f50" : "none",
            boxShadow: "rgba(234, 79, 80, 0.4) 0px 7px 29px 0px",
          }}
        >
          <Card.Img
            className="w-100"
            variant="top"
            src={image ? image : placeholder}
          />
          {!isActive && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: 0.5,
                  zIndex: 1,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${disabledframe})`,
                  backgroundSize: "cover",
                  zIndex: 2,
                }}
              />
            </>
          )}
        </div>

        <Card.Body>
          <Link to={id} className="text-decoration-none">
            <Card.Title
              style={{
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "27px",
                color: "#303972",
              }}
            >
              {Name}
            </Card.Title>
          </Link>
          {ExpiryDate && (
            <Card.Text
              className="text-muted"
              style={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "22.5px",
                color: "#A098AE",
              }}
            >
              Exp. Date: {formattedExpiryDate}
            </Card.Text>
          )}

          {designation && (
            <Card.Text
              className="text-muted"
              style={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "22.5px",
                color: "#A098AE",
              }}
            >
              {designation}
            </Card.Text>
          )}

          {showToggle && (
            <label>
              <Toggle
                icons={{
                  checked: <span style={{ fontSize: "8px" }}> ON</span>,
                  unchecked: <span style={{ fontSize: "8px" }}>OFF</span>,
                }}
                checked={isActive===true ? true : false}
                onChange={handleToggleChange}
              />
            </label>
          )}

          <div className="d-flex gap-4 justify-content-center mt-1">
            {number && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>{number}</Tooltip>}
                >
                  <button
                    className="d-flex justify-content-center align-items-center border-0"
                    style={{
                      background: "#303972",
                      borderRadius: "60px",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ color: "white" }}
                    />
                  </button>
                </OverlayTrigger>
              </>
            )}

            <button
              className="d-flex justify-content-center align-items-center border-0"
              style={{
                background: "#303972",
                borderRadius: "60px",
                height: "30px",
                width: "30px",
              }}
              onClick={handleShowModal}
            >
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "white" }} />
            </button>
          </div>
        </Card.Body>
      </Card>

      <SendSchoolMail
        show={showModal}
        handleClose={handleCloseModal}
        email={email}
      />
      <DeleteSchoolModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default SchoolCards;
