import { GetApiData } from "utils/http-client";


export const CreateExercise = async (data) => {
    return await GetApiData(`/exercise`, 'POST', data, true);
};

export const generateExercise = async (data) => {
    return await GetApiData(`/exercise/ai`, 'POST', data, true);
};

export const generateListeningExercise = async (data) => {
    return await GetApiData(`/exercise/ai/listening`, 'POST', data, true);
};

export const getAllSubject = async () => {
    return await GetApiData(`/subject`, 'GET', null, true);
};
export const getExerciseById = async (id) => {
    return await GetApiData(`/exercise/${id}`, 'GET', null, true);
};
export const createExerciseQuestions = async (id, data) => {
    return await GetApiData(`/exercise/question/${id}`, 'POST', data, true);
};
export const updateExerciseQuestion = async (id, data) => {
    return await GetApiData(`/exercise/question/${id}`, 'PUT', data, true);
};
export const deleteExerciseQuestions = async (exercise,id) => {
    return await GetApiData(`/exercise/question/${exercise}?question=${id}`, 'DELETE', null, true);
};
export const updateExercise = async (id,data) => {
    return await GetApiData(`/exercise/${id}`, 'PUT', data, true);
};
export const addTranslation = async (data) => {
    return await GetApiData(`/exercise/translate/add`, 'POST', data, true);
};
export const generateDifficultyExercise = async (data) => {
    return await GetApiData(`/exercise/ai/difficulty`, 'POST', data, true);
};
export const getAllExercises = async (page) => {
    return await GetApiData(`/exercise/user?page=${page}`, 'GET', null, true);
};
export const getFavouritesExercises = async () => {
    return await GetApiData(`/exercise/favourites`, 'GET', null, true);
};
export const deleteExercises = async (id) => {
    return await GetApiData(`/exercise/${id}`, 'DELETE', null, true);
};
export const removeFavouriteExercises = async (id) => {
    return await GetApiData(`/exercise/favourites/${id}`, 'DELETE', null, true);
};
export const addToFavourites = async (id) => {
    return await GetApiData(`/exercise/favourites/${id}`, 'POST', null, true);
};
export const addFavouriteExercises = async (id) => {
    return await GetApiData(`/exercise/favourites/${id}`, 'POST', null, true);
};

export const translateExerciseAPI = async (data) => {
    return await GetApiData(`/exercise/translate`, 'POST', data, true);
};

export const addToLibrary = async (id) => {
    return await GetApiData(`/exercise/library/request/${id}`, 'PUT', null, true);
};
