import React from 'react';
import { Form } from 'react-bootstrap';

const InputComponent = ({ label, placeholder, value, setValue, required, isInvalid, disabled,type }) => {
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Form.Group className='my-2' controlId={label}>
            {label &&(
                <>
                <Form.Label>{label}</Form.Label>
                </>
            )}
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                min={0}
                required={required}
                isInvalid={isInvalid}
                style={{ borderRadius: "30px" }}
                disabled={disabled}
            />
            {isInvalid && (
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
}

export default InputComponent;
