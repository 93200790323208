import PageHeading from 'components/shared/PageHeading';
import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

const LessonPlanner = () => {
    const localizer = momentLocalizer(moment);

    const formats = {
        dayFormat: (date, culture, localizer) =>
            localizer.format(date, 'dddd, MMM D'),
    };

    const myEventsList = [
        {
            title: `Reading - ${moment(new Date(2024, 8, 25)).format('MMMM D, YYYY')}`,
            start: new Date(2024, 8, 25, 9, 0),
            end: new Date(2024, 8, 25, 10, 0),
            type: 'reading',
        },
        {
            title: `Writing - ${moment(new Date(2024, 8, 26)).format('MMMM D, YYYY')}`,
            start: new Date(2024, 8, 26, 11, 0),
            end: new Date(2024, 8, 26, 12, 0),
            type: 'writing',
        },
        {
            title: `Listening - ${moment(new Date(2024, 8, 27)).format('MMMM D, YYYY')}`,
            start: new Date(2024, 8, 27, 14, 0),
            end: new Date(2024, 8, 27, 15, 30),
            type: 'listening',
        },
        {
            title: `Vocabulary - ${moment(new Date(2024, 8, 28)).format('MMMM D, YYYY')}`,
            start: new Date(2024, 8, 28, 13, 0),
            end: new Date(2024, 8, 28, 15, 0),
            type: 'vocabulary',
        },
        {
            title: `Grammar - ${moment(new Date(2024, 8, 29)).format('MMMM D, YYYY')}`,
            start: new Date(2024, 8, 29, 9, 0),
            end: new Date(2024, 8, 29, 10, 0),
            type: 'grammar',
        },
    ];

    const eventStyleGetter = (event) => {
        let backgroundColor;
        switch (event.type) {
            case 'reading':
                backgroundColor = '#4caf50';
                break;
            case 'writing':
                backgroundColor = '#2196f3';
                break;
            case 'listening':
                backgroundColor = '#ff9800';
                break;
            case 'vocabulary':
                backgroundColor = '#9c27b0';
                break;
            case 'grammar':
                backgroundColor = '#f44336';
                break;
            default:
                backgroundColor = '#ffffff';
        }
        return {
            style: {
                backgroundColor,
                borderRadius: '5px',
                opacity: 0.8,
                color: 'white',
                border: '0px',
                display: 'block',
                width: '100%',
                minHeight: '80px',
            },
        };
    };

    const toolbar = (props) => {
        return (
            <div>
                <button onClick={() => props.onNavigate('PREV')}>Prev</button>
                <span>{`${props.label}`}</span>
                <button onClick={() => props.onNavigate('NEXT')}>Next</button>
            </div>
        );
    };

    return (
        <>
            <PageHeading heading={"Lesson Planner"} />
            <div className='py-5'>
                <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, width: '100%', margin: '0 auto' }} 
                    views={['week']}
                    defaultView={Views.WEEK}
                    step={60}
                    showMultiDayTimes={true}
                    defaultDate={new Date()}
                    formats={formats}
                    eventPropGetter={eventStyleGetter}
                    toolbar={toolbar}
                />
            </div>
        </>
    );
};

export default LessonPlanner;
