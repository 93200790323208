import { GetApiData } from "utils/http-client";


export const getClassTeachersAndStudents = async (id, role) => {
    return await GetApiData(`/class/members/${role}/${id}`, 'GET', null, true);
};

export const getClassDetail = async (id) => {
    return await GetApiData(`/class/${id}`, 'GET', null, true);
};

